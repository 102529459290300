<template>
  <div class="invite-root-container">
    <invite-member ref="inviteMember"/>
  </div>
</template>

<script>
import InviteMember from "./Panel/InviteMember";

export default {
  components: {
    "invite-member": InviteMember,
  },
  methods: {
  },
};
</script>

<style scoped>
.invite-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 50px;
  overflow-y: scroll;
}
</style>